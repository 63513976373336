@font-face {
  font-weight: 400;
  font-family: 'SVN-Circular';
  src: local('SVN-Circular'), url(../../../public/fonts/svn-circular/SVN-Circular-Regular.ttf) format('truetype');
  font-display: swap;
}


@font-face {
  font-weight: 500;
  font-family: 'SVN-Circular';
  src: local('SVN-Circular'), url(../../../public/fonts/svn-circular/SVN-Circular-Medium.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-weight: 700;
  font-family: 'GoogleSans';
  src: local('GoogleSans'), url(../../../public/fonts/svn-circular/SVN-Circular-Bold.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-weight: 400;
  font-family: 'GoogleSans';
  src: local('GoogleSans'), url(../../../public/fonts/google-sans/GoogleSans-Regular.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-weight: 500;
  font-family: 'GoogleSans';
  src: local('GoogleSans'), url(../../../public/fonts/google-sans/GoogleSans-Medium.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-weight: 700;
  font-family: 'SVN-Circular';
  src: local('SVN-Circular'), url(../../../public/fonts/google-sans/GoogleSans-Bold.ttf) format('truetype');
  font-display: swap;
}

.global {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}